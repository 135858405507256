<script lang="ts" setup>
import { NavbarItem } from '@/navigation/components'
import { useCurrency } from '@/ui/composables/useCurrency'
import { useTopUpModal, useUserOrganization } from '@/user/composables'
import { Spinner } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'

const { organization, fetch } = useUserOrganization()
const { formatCurrency } = useCurrency()
const { showTopUp } = useTopUpModal()

onMounted(fetch)
</script>

<template>
  <NavbarItem @click="showTopUp = true">
    <template v-if="organization">
      {{ formatCurrency(Number(organization?.budget)) }}
    </template>
    <Spinner v-else />
  </NavbarItem>
</template>
