import { useApiClient, usePolling } from '@/api'
import {
  MadhatterSortOrder,
  RunStatus,
  type RunByWorkflowIdMutation,
  type RunItemFragment,
  type TabularExportType,
} from '@/generated/sdk'
import { useRunResults } from '@/runs/composables'
import { useConfirmDelete, useSimpleMessage, useUtils } from '@/ui/composables'
import type { ComputedRef, Ref } from 'vue'
import { computed, reactive, ref, watch } from 'vue'

const { debounce } = useUtils()

export type BlockOutputDetails = {
  runs: Ref<RunItemFragment[] | undefined>
  parentWorkflow: ComputedRef<{ id: string; name: string } | null>
  workflowBlock: Ref<{ id: string; name?: string | null } | undefined>
  showMore: ComputedRef<(() => Promise<void>) | null>
}

export function useBlockOutput() {
  const api = useApiClient()
  const { showMessage } = useSimpleMessage()
  const { confirmDelete } = useConfirmDelete()
  const { startPolling } = usePolling(refreshPendingRuns)
  const pagination = reactive({ size: 10, page: 1 })

  const { selectedRunIds, resetSelectedRuns, deleteSelectedRuns } = useRunResults()

  const blockConfigId = ref<string>()
  const workflowBlock = ref<{ id: string; name?: string | null }>()
  const runs = ref<RunItemFragment[]>()
  const filterRating = ref<number>()
  const filterStatus = ref<RunStatus>()
  const search = ref('')

  const parentWorkflow = computed(() => {
    const firstRun = runs.value?.[0]
    if (!firstRun) return null
    const parent = firstRun.parentRun?.blockConfig?.workflow || firstRun.parentRun?.parentRun?.blockConfig?.workflow
    return parent ?? null
  })

  const showMore = computed(() => {
    const hasMore = runs.value?.length === pagination.size * pagination.page
    return hasMore ? loadMore : null
  })

  const debouncedFetch = debounce(resetAndFetchRuns, { delayMs: 500 })

  watch([filterRating, filterStatus], resetAndFetchRuns, { immediate: true })
  watch(search, () => debouncedFetch.trigger())

  function resetFilters() {
    filterRating.value = undefined
    filterStatus.value = undefined
  }

  async function fetchOutput(id: string) {
    blockConfigId.value = id
    await resetAndFetchRuns()
    startPolling()
  }

  async function resetAndFetchRuns() {
    resetSelectedRuns()
    runs.value = undefined
    pagination.page = 1
    runs.value = await fetch()
  }

  async function fetch() {
    if (!blockConfigId.value) return
    const response = await api.client.getBlockConfigOutput({
      blockConfigId: blockConfigId.value,
      queryArgs: {
        ...pagination,
        queryString: search.value,
        sort: [{ fieldName: 'createdAt', order: MadhatterSortOrder.Desc }],
      },
      rating: filterRating.value,
      status: filterStatus.value,
    })
    workflowBlock.value = response.blockConfig[0]?.workflowBlock?.[0]
    return response.run
  }

  async function loadMore() {
    pagination.page++
    const nextPage = await fetch()
    runs.value = [...(runs.value ?? []), ...(nextPage ?? [])]
  }

  async function refreshPendingRuns() {
    // Refresh only pending runs
    if (!runs.value) return
    const unfinishedStatuses = [RunStatus.Pending, RunStatus.Running]
    const unfinishedRuns = runs.value.filter((run) => unfinishedStatuses.includes(run.status))
    if (unfinishedRuns.length === 0) return
    const runIds = unfinishedRuns.map((run) => run.id)
    const res = await api.client.runs({ input: runIds.map((id) => ({ id })), queryArgs: { size: 10 } })
    for (const run of res.run) {
      const index = runs.value.findIndex((r) => r.id === run.id)
      if (index >= 0) runs.value?.splice(index, 1, run)
    }
  }

  async function rerunRuns(workflowId: string) {
    const promises: Promise<RunByWorkflowIdMutation>[] = []
    selectedRunIds.value.forEach((runId) => {
      const run = runs.value?.find((r) => r.id === runId)
      if (!run || !blockConfigId.value) return
      promises.push(api.client.runByWorkflowId({ workflowId, data: run.input }))
    })
    await Promise.all(promises)
  }

  async function handleRerunSelectedRuns(workflowId: string) {
    await rerunRuns(workflowId)
    await resetAndFetchRuns()
    resetSelectedRuns()
    showMessage('Runs rerun')
  }

  async function handleDeleteSelectedRuns() {
    if (await confirmDelete({ type: 'selection' })) {
      await deleteSelectedRuns()
      await resetAndFetchRuns()
      resetSelectedRuns()
      showMessage('Runs deleted')
    }
  }

  async function downloadResultsLatestRun(type: TabularExportType) {
    const runId = runs.value?.[0]?.id
    if (runId == null) return
    const response = await api.client.downloadRunByRunIds({ runIds: [runId], type })
    await api.downloadFile(response.downloadRunByRunIds)
  }

  async function downloadResultsAllRuns(workflowId: string, type: TabularExportType) {
    const response = await api.client.downloadRunsByWorkflowId({ workflowId, type })
    await api.downloadFile(response.downloadRunsByWorkflowId)
  }

  async function downloadSelectedRuns(type: TabularExportType) {
    if (selectedRunIds.value.length === 0) return
    const response = await api.client.downloadRunByRunIds({ runIds: selectedRunIds.value, type })
    await api.downloadFile(response.downloadRunByRunIds)
    resetSelectedRuns()
  }

  function handleFilterRatingChange(rating: number | null) {
    filterRating.value = rating ?? undefined
  }

  return {
    blockConfigId,
    runs,
    showMore,
    parentWorkflow,
    search,
    workflowBlock,
    filterRating,
    filterStatus,
    statusOptions,
    resetFilters,
    fetchOutput,
    rerunRuns,
    selectedRunIds,
    downloadResultsLatestRun,
    downloadResultsAllRuns,
    downloadSelectedRuns,
    handleDeleteSelectedRuns,
    handleRerunSelectedRuns,
    handleFilterRatingChange,
  }
}

const statusOptions = [
  { label: 'All', value: undefined },
  ...Object.values(RunStatus).map((status) => ({ label: status, value: status })),
]
