<script setup lang="ts">
import { useUtils } from '@/ui/composables'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { Column, FormItem, Tabs, TextInput, Textarea } from '@madxnl/dodo-ui'
import { BlockSettings } from '../block-sidebar'
import WorkflowApiCall from './WorkflowApiCall.vue'
import WorkflowPublicAccess from './WorkflowPublicAccess.vue'
import WorkflowScheduledRun from './WorkflowScheduledRun.vue'
import WorkflowSettings from './WorkflowSettings.vue'

const { workflow, updateWorkflow } = useWorkflowDetails()
const { debounce } = useUtils()

const workflowSettingsTabs = [
  { key: 'general' as const, name: 'General', slot: 'general' },
  { key: 'input' as const, name: 'Input', slot: 'input' },
  { key: 'output' as const, name: 'Output', slot: 'output' },
]

const setWorkflowNameDesc = debounce(async () => {
  const name = workflow.value?.name
  const description = workflow.value?.description
  await updateWorkflow({ description, name })
})
</script>

<template>
  <template v-if="workflow">
    <Tabs :tabs="workflowSettingsTabs" style="flex-grow: 1">
      <template #general>
        <Column gap="l">
          <FormItem label="Name">
            <TextInput
              v-model="workflow.name"
              name="name"
              :placeholder="workflow.name"
              :disabled="!workflow?.draft"
              @update:model-value="setWorkflowNameDesc.trigger"
            />
          </FormItem>

          <FormItem label="Description">
            <Textarea
              v-model="workflow.description"
              :min-rows="2"
              :max-rows="6"
              placeholder="Add a description"
              :disabled="!workflow.draft"
              @update:model-value="setWorkflowNameDesc.trigger"
            />
          </FormItem>

          <hr />
          <h4>Advanced</h4>
          <WorkflowPublicAccess />
          <WorkflowScheduledRun />
          <WorkflowApiCall />
        </Column>
      </template>

      <template #input>
        <WorkflowSettings />
      </template>

      <template #output>
        <BlockSettings v-if="workflow" :workflow="workflow" context="workflow-output" />
      </template>
    </Tabs>
  </template>
</template>

<style module>
.code {
  background: var(--grey-1-altbg);
  border: 1px solid var(--grey-2-lines);
  border-radius: 8px;
}

.code pre {
  width: 568px;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
</style>
