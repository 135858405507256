<script lang="ts" setup>
import { Column } from '@madxnl/dodo-ui'
import RunWorkflowArgument from './RunWorkflowArgument.vue'
import { type useRunWorkflowForm } from './useRunWorkflowForm'

const props = defineProps<{
  runForm: ReturnType<typeof useRunWorkflowForm>
}>()

const { shownArgNames, getArgDetails, getArgWarn, getArgError } = props.runForm
</script>

<template>
  <Column>
    <template v-for="argName of shownArgNames" :key="argName">
      <RunWorkflowArgument
        :name="argName"
        :argument="getArgDetails(argName)"
        :error="getArgError(argName)"
        :warn="getArgWarn(argName)"
        :input-data="runForm.data.dataObj"
      />
    </template>
  </Column>
</template>
