import { useWorkflowDetails } from '@/workflow-edit/composables'
import { useValidation } from '@madxnl/dodo-ui'
import cronstrue from 'cronstrue'
import { computed, ref, watch } from 'vue'

export function useWorkflowScheduledRun() {
  const { workflow, updateWorkflow } = useWorkflowDetails()

  const cronSchedule = ref(workflow.value?.cronSchedule ?? '0 */1 * * *')
  const enableSchedule = ref(workflow.value?.enableSchedule ?? false)

  const { errors, validate } = useValidation({
    cronSchedule: { value: cronSchedule, validators: [validateCron] },
  })

  const readableCron = computed(() => {
    try {
      return cronstrue.toString(cronSchedule.value)
    } catch (e) {
      return 'Invalid cron expression'
    }
  })

  function validateCron(value?: string | null) {
    try {
      cronstrue.toString(value ?? '')
      return undefined
    } catch (e) {
      return 'Invalid cron expression'
    }
  }

  const parsedCron = computed(() => {
    const [minute, hour, day, month, weekday] = cronSchedule.value?.split(' ') ?? []
    return { minute, hour, day, month, weekday }
  })

  const repeatUnit = computed(() => {
    const { minute, hour, day, month, weekday } = parsedCron.value
    if (isEvery(minute) && hour === '*' && day === '*' && month === '*' && weekday === '*') return 'minute'
    if (isEvery(hour) && minute === '0' && day === '*' && month === '*' && weekday === '*') return 'hour'
    if (isEvery(day) && isNumber(minute) && isNumber(hour) && month === '*' && weekday === '*') return 'day'
    if (isEvery(month) && isNumber(minute) && isNumber(hour) && weekday === '*') return 'month'
    if (isOneOrMore(weekday) && isNumber(minute) && isNumber(hour) && day === '*' && month === '*') return 'week'
    return 'advanced'
  })

  const repeatEvery = computed(() => {
    const { minute, hour, day, month } = parsedCron.value
    if (minute?.startsWith('*/')) return Number(minute.split('*/')[1])
    if (hour?.startsWith('*/')) return Number(hour.split('*/')[1])
    if (day?.startsWith('*/')) return Number(day.split('*/')[1])
    if (month?.startsWith('*/')) return Number(month.split('*/')[1])
    return null
  })

  const repeatTime = computed(() => {
    const { minute, hour } = parsedCron.value
    const date = new Date(Date.UTC(0))
    if (!isNaN(Number(minute))) date.setMinutes(Number(minute))
    if (!isNaN(Number(hour))) date.setHours(Number(hour))
    return date.toISOString().slice(11, 16)
  })

  const repeatMinutes = computed(() => extractMinutes(repeatTime.value))
  const repeatHours = computed(() => extractHours(repeatTime.value))

  const repeatWeekdays = computed(() => {
    const { weekday } = parsedCron.value
    if (isOneOrMore(weekday)) return weekday?.split(',').map((v) => Number(v)) ?? []
    return []
  })

  const repeatMonthDays = computed(() => {
    const { day } = parsedCron.value
    if (isOneOrMore(day)) return day?.split(',').map((v) => Number(v)) ?? []
    return []
  })

  watch(
    cronSchedule,
    async (cronSchedule) => {
      if (!workflow.value || !enableSchedule.value) return
      if (cronSchedule === workflow.value.cronSchedule) return
      const valid = await validate()
      if (!valid) return
      await updateWorkflow({ cronSchedule })
    },
    { immediate: true },
  )

  watch(
    enableSchedule,
    async (enableSchedule) => {
      if (!workflow.value) return
      if (enableSchedule === workflow.value.enableSchedule) return
      await updateWorkflow({ enableSchedule, cronSchedule: cronSchedule.value })
    },
    { immediate: true },
  )

  return {
    cronSchedule,
    enableSchedule,
    repeatUnit,
    repeatEvery,
    repeatTime,
    repeatMinutes,
    repeatHours,
    repeatWeekdays,
    repeatMonthDays,
    repeatUnitOptions,
    weekdays,
    monthDays,
    errors,
    readableCron,
    extractMinutes,
    extractHours,
  }
}

const repeatUnitOptions = [
  { label: 'Minutely', value: 'minute' },
  { label: 'Hourly', value: 'hour' },
  { label: 'Daily', value: 'day' },
  { label: 'Weekly', value: 'week' },
  { label: 'Monthly', value: 'month' },
]
const weekdays = { 1: 'M', 2: 'T', 3: 'W', 4: 'T', 5: 'F', 6: 'S', 7: 'S' }
const monthDays = Array.from({ length: 31 }, (_, i) => i + 1)

function isNumber(value?: string) {
  return !isNaN(Number(value))
}

function isEvery(value?: string) {
  return value?.startsWith('*/') && isNumber(value.split('*/')[1])
}

function isOneOrMore(value?: string) {
  return (
    (isNumber(value) && Number(value) > 0) ||
    (value?.split(',').some((v) => isNumber(v) && Number(v) > 0) && value?.split(',').length > 1)
  )
}

const extractMinutes = (time: string) => Number(time.split(':')[1] ?? 0)
const extractHours = (time: string) => Number(time.split(':')[0] ?? 0)
