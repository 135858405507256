<script setup lang="ts">
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { Button, Row } from '@madxnl/dodo-ui'
import { onMounted } from 'vue'
import { useChargebee } from '../composables'
const { createChargebeePortal, openChargebeePortal } = useChargebee()

onMounted(async () => {
  await createChargebeePortal()
})
</script>

<template>
  <Row>
    <Button @click="openChargebeePortal('SUBSCRIPTION_DETAILS')">
      <TwinIcon icon="Settings" />
      Change subscription
    </Button>
    <Button @click="openChargebeePortal('BILLING_HISTORY')">
      <TwinIcon icon="Eye" />
      View invoices
    </Button>
    <Button @click="openChargebeePortal('PAYMENT_SOURCES')">
      <TwinIcon icon="CreditCard" />
      Update payment method
    </Button>
  </Row>
</template>
