export function useCurrency() {
  const locale = 'en-GB'

  function formatCurrency(amount: number, currency = 'EUR'): string {
    const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency })
    return formatter.format(amount)
  }

  function getCurrencySymbol(currency: 'EUR') {
    const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency })
    const parts = formatter.formatToParts(0)
    const symbol = parts.find((part) => part.type === 'currency')?.value
    return symbol ?? currency // Fallback to currency code if symbol is not found
  }

  return {
    getCurrencySymbol,
    formatCurrency,
  }
}
