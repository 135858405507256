<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { Button, Column, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive } from 'vue'
import { useWorkflowSettings } from './composables'
import WorkflowSetting from './WorkflowSetting.vue'

const { workflow } = useWorkflowDetails()
const { createWorkflowArg, workflowInputArguments } = useWorkflowSettings()

const disabled = computed(() => !workflow.value?.draft)
const isNameTaken = computed(() => !!workflowInputArguments?.value.find((a) => a.name === data.name))

const data = reactive({
  name: '',
})

async function clickAddSetting() {
  await createWorkflowArg(data.name)
  data.name = ''
}
</script>

<template>
  <Column gap="l">
    <p v-if="!workflowInputArguments.length" class="form-description">
      Add the expected input data to be used by the blocks in the workflow. When a new Run is started, the user is asked
      to submit the input data.
    </p>

    <template v-else>
      <WorkflowSetting
        v-for="setting of workflowInputArguments"
        :key="setting.id"
        :setting="setting"
        :disabled="disabled"
      />
    </template>

    <Form v-if="!disabled" @submit="clickAddSetting">
      <hr />
      <h5>Add expected input data</h5>
      <FormItem>
        <Row>
          <TextInput v-model="data.name" style="flex: 1" type="text" placeholder="Enter a name" />
          <Button :disabled="data.name === '' || isNameTaken" type="submit" square @click.prevent="clickAddSetting">
            <TwinIcon icon="Plus" />
          </Button>
        </Row>
      </FormItem>
    </Form>
  </Column>
</template>
