import { useApiClient } from '@/api'
import { useSimpleMessage } from '@/ui/composables'
import { computed } from 'vue'

export function useResetElasticSearch() {
  const isLocalDevelop = location.hostname === 'localhost'
  const { fetchUrl } = useApiClient()
  const { showMessage } = useSimpleMessage()

  async function doResetElasticSearch() {
    // Reset elastic search in local development
    const result = await fetchUrl('reset-es-node')
    showMessage(result)
  }

  const resetElasticSearch = computed(() => {
    return isLocalDevelop ? doResetElasticSearch : null
  })

  return { resetElasticSearch }
}
