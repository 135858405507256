<script setup lang="ts">
import { TwinIcon, type TwinIconName } from '@/ui/components'
import { ref } from 'vue'

const bullet = ref<HTMLElement>()

defineProps<{
  icon: TwinIconName
  disabled?: boolean
  hoverState?: boolean
}>()

defineExpose({
  bullet,
})
</script>

<template>
  <button ref="bullet" :class="[$style.bullet, hoverState && $style.hoverState]" type="button" :disabled="disabled">
    <TwinIcon :icon="icon" />
  </button>
</template>

<style module>
.bullet {
  --radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: calc(var(--radius) * 2);
  height: calc(var(--radius) * 2);
  border-radius: 50%;
  background-color: var(--grey-0-white);
  border: 1px solid var(--grey-3-outlines);
  box-shadow: var(--drop-shadow);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  color: transparent;
  cursor: pointer;
  top: 0;
  left: 50%;
}

.hoverState:not(:disabled) {
  --radius: 14px;
  border: 1px solid var(--grey-4-disabled);
  color: inherit;
}

.bullet:hover:not(:disabled) {
  background-color: var(--grey-2-lines);
}

.bullet:active:not(:disabled) {
  background-color: var(--grey-3-outlines);
}

.bullet:focus {
  outline: none;
}
</style>
