<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { MainPageLayout } from '@/navigation/components'
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import { useEditorLinks, useWorkflowDetails } from '@/workflow-edit/composables'
import { Button, Column, Dropdown, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import DataPageTable from './DataPageTable.vue'
import RunRating from './RunRating.vue'
import { useWorkflowData } from './useWorkflowData'

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { workflow } = useWorkflowDetails()
const { currentBlockConfig, blockConfigSelectOptions, selectedBlockConfigId, blockOutput } = useWorkflowData()

const props = defineProps<{
  workflowId: string
}>()

async function handleDownloadResults(type: TabularExportType, isLatest: boolean) {
  if (isLatest) await blockOutput.downloadResultsLatestRun(type)
  else await blockOutput.downloadResultsAllRuns(props.workflowId, type)
}

async function handleOpenParentWorkflow() {
  const parentWorkflow = blockOutput.parentWorkflow.value
  const workflowBlock = blockOutput.workflowBlock.value
  if (parentWorkflow == null) return
  if (workflowBlock == null) return await router.push(links.workflowData(parentWorkflow.id))
  await router.push(linkReviewData(parentWorkflow.id, workflowBlock.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template v-if="workflow">
      <Column gap="xl">
        <Column>
          <Row>
            <Row grow>
              <h1>Results of workflow: {{ workflow.name }}</h1>
            </Row>

            <Row>
              <Dropdown class="navbar-dropdown">
                <template #trigger="{ toggle }">
                  <Button @click="toggle">
                    <TwinIcon icon="Download" />
                    Download
                  </Button>
                </template>
                <template #content>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, false)"
                  >
                    Download all ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                  <Button
                    v-for="(type, index) in [TabularExportType.Excel, TabularExportType.Csv]"
                    :key="index + 2"
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="handleDownloadResults(type, true)"
                  >
                    Download latest ({{ type === TabularExportType.Excel ? '.xlsx' : '.csv' }})
                  </Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <p class="form-description">
            Here you can find the latest workflow results. Every line is an instance of the workflow, called a run.<br />
            Each run has a result, as shown in the first column of the table below. The input used to run the workflow
            is visible in the columns after the result column.
          </p>
        </Column>

        <Column>
          <h3>Workflow output and input data</h3>

          <Row padding="m" justify="between" :class="$style.ribbon">
            <Row gap="m">
              <h4>Runs from</h4>

              <Row>
                <Select
                  v-if="blockConfigSelectOptions"
                  v-model="selectedBlockConfigId"
                  :options="blockConfigSelectOptions"
                  style="max-width: 480px"
                />
                <Button
                  v-if="currentBlockConfig !== workflow.mainBlockConfig"
                  square
                  variant="clear"
                  color="primary"
                  title="Open parent workflow"
                  @click="handleOpenParentWorkflow"
                >
                  <TwinIcon icon="LinkExternal" />
                </Button>
              </Row>
            </Row>

            <Row>
              <TextInput v-model="blockOutput.search.value" type="search" placeholder="Search" />

              <Dropdown>
                <template #trigger="{ toggle }">
                  <Button variant="solid" color="primary" @click="toggle">
                    <TwinIcon icon="Filter" />
                    Filter
                  </Button>
                </template>
                <template #content>
                  <FormItem label="Filter by Status">
                    <Select v-model="blockOutput.filterStatus.value" :options="blockOutput.statusOptions" />
                  </FormItem>
                  <FormItem label="Filter by rating">
                    <RunRating
                      :rating="blockOutput.filterRating.value"
                      @update-rating="blockOutput.handleFilterRatingChange"
                    />
                  </FormItem>
                  <hr />
                  <Button variant="clear" @click="blockOutput.resetFilters">Reset filters</Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <DataPageTable
            v-if="currentBlockConfig"
            :block-config="currentBlockConfig"
            :block-output="blockOutput"
            :input-arguments="workflow.inputArguments"
          />
        </Column>
      </Column>
    </template>

    <template #footer>
      <DataActionBar
        :selected-run-ids="blockOutput.selectedRunIds.value"
        :is-workflow-draft="workflow?.draft ?? true"
        @rerun-selected-runs="() => blockOutput.handleRerunSelectedRuns(workflowId)"
        @download-selected-runs="blockOutput.downloadSelectedRuns"
        @delete-selected-runs="blockOutput.handleDeleteSelectedRuns"
      />
    </template>
  </MainPageLayout>
</template>

<style module>
.ribbon {
  background-color: var(--grey-0-white);
  border-top: 2px solid var(--grey-2-lines);
  border-bottom: 2px solid var(--grey-2-lines);
}

.dropdownButton {
  justify-content: start;
}
</style>
