import type { RunItemFragment } from '@/generated/sdk'
import { computed, ref } from 'vue'

export function useDataTable() {
  const tableRuns = ref<RunItemFragment[] | undefined>()
  const inputArguments = ref<{ name: string }[] | undefined>()

  const outputHeaders = computed(() => {
    if (!tableRuns.value) return null
    return getHeaders(tableRuns.value.map((run) => run.output))
  })

  const inputHeaders = computed(() => {
    if (!tableRuns.value) return null
    if (inputArguments.value) return inputArguments.value.map(({ name }) => name)
    return getHeaders(tableRuns.value?.map((run) => run.input))
  })

  const rows = computed(() => {
    if (!tableRuns.value) return []
    return tableRuns.value.map((run) => {
      const output = getValues(run.output, outputHeaders.value, 'Output')
      const input = getValues(run.input, inputHeaders.value, 'Input')

      const data: Record<string, unknown> = {
        runId: run.id,
        Run: run.createdAt,
        Status: run.status,
        Rating: run.rating,
        ...output,
        ...input,
      }
      return data
    })
  })

  const allHeaders = computed(() => {
    if (!rows.value) return []
    const finishedRows = rows.value.filter((row) => row.Status !== 'Running' && row.Status !== 'Pending')
    const firstValue = finishedRows.find((obj) => obj)
    if (!firstValue) return []
    const data = firstValue
    if (data._modelName === 'file') {
      return ['File']
    }
    return Object.keys(firstValue)
  })

  return { tableRuns, allHeaders, inputHeaders, outputHeaders, rows, inputArguments }
}

function getValues(obj: unknown, headers: string[] | null, type: 'Input' | 'Output') {
  // No headers, so we only have an input/output column
  if (!headers) return { [type]: obj }

  if (obj && typeof obj === 'object' && '_modelName' in obj && obj._modelName === 'file') {
    return { File: obj }
  }

  const record: Record<string, unknown> = {}
  if (obj && typeof obj === 'object') Object.assign(record, obj)

  return headers.reduce(
    (acc, header) => {
      acc[header] = header in record ? record[header] : null
      return acc
    },
    {} as Record<string, unknown>,
  )
}

function getHeaders(objects: unknown[]) {
  const firstValue = objects.find((obj) => obj)

  // We do not convert arrays or primitives
  if (!firstValue || Array.isArray(firstValue) || typeof firstValue !== 'object') {
    return null
  }

  return [...new Set(objects.flatMap((obj) => (obj != null && typeof obj === 'object' ? Object.keys(obj) : [])))]
}
