import { crashPlugin } from '@madxnl/dodo-ui'
import 'reflect-metadata'
import { createApp } from 'vue'
import { hotjarPlugin, sentryPlugin } from './analytics'
import { App } from './app'
import { router } from './router/router'

const app = createApp(App)

const crash = crashPlugin({
  router,
  onError: (error) => {
    // Ignore ResizeObserver error caused by LastPass
    const resizeObserverError = 'ResizeObserver loop completed with undelivered notifications'
    if (error?.toString().includes(resizeObserverError)) {
      console.warn(`Ignored error: ${resizeObserverError}`) // eslint-disable-line no-console
      return false
    }
  },
})

app.use(sentryPlugin)
app.use(hotjarPlugin)
app.use(crash)
app.use(router)

app.mount('#app')
