<script lang="ts" setup>
import { TwinIcon } from '@/ui/components'
import { Button, Form } from '@madxnl/dodo-ui'
import { LoginSignupTabs, SignupAccount, SignupBilling, SignupPayment, SignupSubscription } from '.'
import { useSignupForm } from '../composables'
import AuthPageLayout from './AuthPageLayout.vue'

const { step, previousStep, submitStep, submitWithoutPayment, Steps, StepTitles, paymentInfoCompleted } =
  useSignupForm()
</script>

<template>
  <AuthPageLayout
    :title="StepTitles[step]"
    :subtitle="step === Steps.Account ? 'Your account' : undefined"
    :full-width="step === Steps.Subscription"
  >
    <template v-if="step !== Steps.Account" #back>
      <Button variant="link" color="primary" @click="previousStep">
        <TwinIcon icon="ChevronLeft" />
        Back
      </Button>
    </template>

    <template v-if="step === Steps.Account" #tabs>
      <LoginSignupTabs />
    </template>

    <Form @submit="submitStep">
      <SignupAccount v-if="step === Steps.Account" />
      <SignupSubscription v-if="step === Steps.Subscription" />
      <SignupBilling v-if="step === Steps.BillingPayment" />
    </Form>

    <template v-if="step === Steps.BillingPayment" #secondCard>
      <Form @submit="submitStep">
        <SignupPayment />
      </Form>
    </template>

    <template v-if="step === Steps.Account" #disclaimer>
      <p :class="$style.footer">
        You can invite and manage team members later. By continuing, you agree to the <u>terms and conditions</u> and
        the <u>privacy policy</u>.
      </p>
    </template>

    <template v-if="step !== Steps.Subscription" #formActions>
      <Button v-if="step === Steps.Account" variant="solid" color="primary" @click="submitWithoutPayment">
        Get started for free
      </Button>

      <Button
        variant="solid"
        color="primary"
        :disabled="step === Steps.BillingPayment ? !paymentInfoCompleted : false"
        @click="submitStep"
      >
        {{ step === Steps.Account ? 'Choose your plan' : 'Complete payment' }}
      </Button>
    </template>
  </AuthPageLayout>
</template>

<style module>
.footer {
  color: var(--grey-4-disabled);
}
</style>
