import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { ref } from 'vue'
import { useWorkflowBlocks } from './useWorkflowBlocks'
import { useWorkflowDetails } from './useWorkflowDetails'

const dragConnectState = ref<{
  fromBlock: WorkflowBlockItemFragment
  fromInput: boolean
  condition: undefined | string
}>()

export function useDragToConnect() {
  // Connect blocks by dragging a line from one block to another

  const { workflow } = useWorkflowDetails()
  const { connectBlocks } = useWorkflowBlocks({ workflow })

  function beginDragConnect(props: { fromBlock: WorkflowBlockItemFragment; isInput: boolean; condition?: string }) {
    dragConnectState.value = {
      fromInput: props.isInput,
      condition: props.condition,
      fromBlock: props.fromBlock,
    }
    document.addEventListener('pointerup', pointerup)
  }

  function pointerup() {
    document.removeEventListener('pointerup', pointerup)
    dragConnectState.value = undefined
  }

  async function endDragConnect(workflowBlock: WorkflowBlockItemFragment, endCondition: string | null | undefined) {
    // Release drag connect on top of this block
    if (!dragConnectState.value) return
    const { fromBlock, condition: dragFromCondition, fromInput } = dragConnectState.value
    dragConnectState.value = undefined
    if (fromBlock.id === workflowBlock.id) return
    const [blockA, blockB] = fromInput ? [workflowBlock, fromBlock] : [fromBlock, workflowBlock]
    const validConditions = getValidConditions(blockA)
    const defaultCondition = validConditions[0]
    const currentCondition = fromInput ? endCondition : dragFromCondition
    const condition = validConditions.includes(currentCondition) ? currentCondition : defaultCondition
    await connectBlocks(blockA, { id: blockB.id, condition })
  }

  function getValidConditions(workflowBlock: WorkflowBlockItemFragment) {
    const isIfElse = workflowBlock.blockType === WorkflowBlockType.IfElse
    const isSwitch = workflowBlock.blockType === WorkflowBlockType.Switch
    const switchConditions = workflowBlock.nextBlocks?.map((b) => b.condition) ?? []
    return isSwitch ? switchConditions : isIfElse ? ['true', 'false'] : [undefined]
  }

  return { dragConnectState, beginDragConnect, endDragConnect }
}
