<script lang="ts" setup>
import { Navbar } from '@/navigation/components'
import { Card, Column, Row } from '@madxnl/dodo-ui'
import { type Slots, useSlots } from 'vue'

defineProps<{
  title: string
  subtitle?: string
  fullWidth?: boolean
  centered?: boolean
}>()

const slots: Slots = useSlots()
</script>

<template>
  <Navbar :nav-entries="[]" />

  <Column :class="$style.container" align="center" grow>
    <Column :class="[$style.layout, !fullWidth && $style.columns, centered && $style.centered]" gap="xl">
      <Row>
        <Column gap="xl" :class="$style.column" :grow="!!fullWidth">
          <Column gap="m">
            <Row v-if="slots.back">
              <slot name="back" />
            </Row>
            <h1>{{ title }}</h1>
          </Column>

          <slot name="tabs" />
        </Column>
      </Row>

      <Row gap="l" align="start">
        <template v-if="fullWidth">
          <Column :gap="centered ? 'm' : 'xl'" grow>
            <slot />
            <p v-if="subtitle">{{ subtitle }}</p>
          </Column>
        </template>

        <template v-else>
          <Column gap="l" :class="$style.column">
            <h2 v-if="subtitle">{{ subtitle }}</h2>

            <Card :class="$style.card" padding="l" gap="l">
              <slot />
            </Card>

            <slot name="disclaimer" />
          </Column>

          <Column v-if="slots.secondCard" gap="l" :class="$style.column">
            <Card :class="$style.card" padding="l" gap="l">
              <slot name="secondCard" />
            </Card>
          </Column>
        </template>
      </Row>

      <Row v-if="slots.formActions">
        <Column gap="m" :class="$style.column">
          <slot name="formActions" />
        </Column>
      </Row>
    </Column>
  </Column>
</template>

<style module>
.container {
  overflow-y: scroll;
}
.layout {
  width: 1600px;
  max-width: 100%;
  padding: var(--dodo-spacing-xl) var(--dodo-spacing-m);
  flex-grow: 1;
}
.centered {
  text-align: center;
}
.columns .column {
  flex: calc(100% / 3) 0 0;
  min-width: 0;
}
.card {
  min-width: 320px;
}
</style>
