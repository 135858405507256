<script setup lang="ts">
import type { BlockItemFragment } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useDraggable, useUtils } from '@/ui/composables'
import { Button, Card, Column, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import EditorBlockIcon from '../block/EditorBlockIcon.vue'
import { useBlockTypes, useEditorView, useWorkflowEditor } from '../composables'

const { blockTypeCategories } = useBlockTypes()
const { addNewBlock } = useWorkflowEditor()
const { screenToViewCoords, containerRect } = useEditorView()
const { asyncContext, fuzzyMatch } = useUtils()

const props = defineProps<{
  disabled: boolean
}>()

defineEmits<{
  clickInfo: [blockId: string]
}>()

const collapsedCategories = ref<string[]>([])
const searchText = ref('')

watch(
  searchText,
  (t) => {
    if (t === '') collapsedCategories.value = blockTypeCategories.value.map((c) => c.name)
    else collapsedCategories.value = []
  },
  { immediate: true },
)

const filteredBlockTypeCategories = computed(() => {
  if (searchText.value === '') return blockTypeCategories.value
  const groups = blockTypeCategories.value.map((category) => {
    const blockTypes = category.blockTypes?.filter((b) => fuzzyMatch(searchText.value, b.readableName))
    if (!blockTypes || blockTypes.length === 0) return null
    return { ...category, blockTypes }
  })
  return groups.filter((g) => g !== null)
})

const itemBeingDragged = ref<BlockItemFragment>()
const itemDimensions = { width: 320 - 32, height: 64 }

const dragPosStyle = computed(() => {
  return {
    left: drag.position.value.x + 'px',
    top: drag.position.value.y + 'px',
    width: itemDimensions.width + 'px',
    height: itemDimensions.height + 'px',
  }
})

const drag = useDraggable({
  drag(dx, dy) {},
  end(ev) {
    const item = itemBeingDragged.value!
    itemBeingDragged.value = undefined

    const { x, y } = drag.position.value
    const { left, top, height, width } = containerRect.value
    const insideContainerRect = x >= left && x <= left + width && y >= top && y <= top + height
    if (!insideContainerRect) return // cancel

    const [vx, vy] = screenToViewCoords([x, y])
    const dims = itemDimensions
    const pos: [number, number] = [vx - dims.width / 2, vy - dims.height / 2]
    const blockType = item.workflowBlockType
    const blockConfigBlock = item.id

    asyncContext(async () => {
      await addNewBlock({ pos, blockType, blockConfigBlock }, { select: true })
    })
  },
})

function startDrag(event: PointerEvent, blockType: BlockItemFragment) {
  if (props.disabled) return
  itemBeingDragged.value = blockType
  drag.startDrag(event)
}

function toggleCollapsed(categoryName: string) {
  if (collapsedCategories.value.includes(categoryName)) {
    collapsedCategories.value = collapsedCategories.value.filter((c) => c !== categoryName)
  } else {
    collapsedCategories.value = [...collapsedCategories.value, categoryName]
  }
}
</script>

<template>
  <Column gap="l">
    <TextInput v-model="searchText" type="text" placeholder="Type to search" />

    <Column v-if="filteredBlockTypeCategories?.length === 0">
      <p>No results found</p>
    </Column>

    <Column v-for="category of filteredBlockTypeCategories" :key="category.name">
      <Row>
        <Button
          variant="link"
          style="width: 100%; justify-content: flex-start"
          @click="() => toggleCollapsed(category.name)"
        >
          <TwinIcon :icon="collapsedCategories.includes(category.name) ? 'ChevronRight' : 'ChevronDown'" />
          <h4>{{ category.name }}</h4>
        </Button>
      </Row>

      <Column v-if="!collapsedCategories.includes(category.name)">
        <Card
          v-for="b of category.blockTypes"
          :key="b.id"
          :class="[$style.blockTypeBtn, disabled && $style.disabled]"
          hoverable
          @pointerdown.left="startDrag($event, b)"
        >
          <Column gap="s">
            <Row justify="between">
              <Row>
                <EditorBlockIcon :block="b.id" />
                <h4>{{ b.readableName }}</h4>
              </Row>
              <Button
                square
                size="s"
                variant="clear"
                title="Block type info"
                @pointerdown.stop="() => $emit('clickInfo', b.id)"
              >
                <TwinIcon icon="Info" />
              </Button>
            </Row>
          </Column>
        </Card>
      </Column>
    </Column>
  </Column>

  <Teleport to="body">
    <Card v-if="itemBeingDragged" :class="$style.dragging" :style="dragPosStyle">
      <Row>
        <EditorBlockIcon :block="itemBeingDragged.id" />
        <h4>{{ itemBeingDragged.readableName }}</h4>
      </Row>
    </Card>
  </Teleport>
</template>

<style module>
.blockTypeBtn {
  cursor: grab;
  user-select: none;
}
.dragging {
  box-shadow: var(--card-shadow-hover);
  position: fixed;
  overflow: hidden;
  z-index: 100;
  cursor: grabbing;
  outline: 2px solid var(--dodo-color-primary);
  transform: translate(-50%, -50%);
  transition: none;
}
.disabled {
  cursor: default;
  opacity: 0.5;
}
</style>
