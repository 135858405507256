import { getCurrentInstance, onUnmounted, ref, watch } from 'vue'

export function usePolling(callback: () => Promise<unknown>, interval = 1000) {
  const trigger = ref<boolean>(false)
  const timer = ref<ReturnType<typeof setTimeout>>()

  watch(trigger, async () => {
    if (trigger.value == false) return
    trigger.value = false
    await callback()
  })

  const startPolling = () => {
    if (timer.value != null) return // Prevent multiple intervals
    timer.value = setInterval(() => {
      trigger.value = true
    }, interval)
  }

  const stopPolling = () => {
    clearInterval(timer.value)
    delete timer.value
  }

  if (getCurrentInstance()) {
    onUnmounted(stopPolling)
  }

  return { startPolling, stopPolling }
}
