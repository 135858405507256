import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import type { OrganizationDetailsFragment } from '@/generated/sdk'
import { computed, ref } from 'vue'

const organization = ref<OrganizationDetailsFragment>()

export function useUserOrganization() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const insufficientFunds = computed(() => organization.value?.budget && Number(organization.value.budget) < 0)

  async function fetch() {
    const organizationId = currentUser.value?.organization?.id
    if (!organizationId) throw new Error('User has no organization')

    const res = await client.organization({ id: organizationId })
    organization.value = res.organization[0]
  }

  async function deleteInvite(inviteId: string) {
    await client.deleteInvite({ inviteId })
    await fetch()
  }

  async function resendInvite(inviteId: string) {
    // For now we re-create the invite
    const invite = organization.value?.invitees.find((i) => i.id === inviteId)
    if (!invite) throw new Error('Invite not found')
    const { name, email } = invite
    await client.deleteInvite({ inviteId })
    await client.createInvite({ input: { name, email } })
    await fetch()
  }

  async function deleteMember(userId: string) {
    await client.deleteUser({ userId })
    await fetch()
  }

  return { organization, fetch, deleteInvite, resendInvite, deleteMember, insufficientFunds }
}
