import { useApiClient } from '@/api'
import {
  type BlockConfigArgumentFragment,
  BlockConfigArgumentType,
  type BlockConfigDetailsFragment,
  type WorkflowBlockItemFragment,
  type WorkflowDetailsFragment,
  type WorkflowItemFragment,
} from '@/generated/sdk'
import type { Ref } from 'vue'

export function useWorkflowBlocks(args: { workflow: Ref<WorkflowDetailsFragment | undefined> }) {
  const { workflow } = args
  const { client } = useApiClient()

  async function updateBlock(
    block: WorkflowBlockItemFragment,
    update: { name?: string | null; condition?: string | null },
  ) {
    const oldName = block.name
    Object.assign(block, update)
    await client.updateWorkflowBlock({ input: { ...update, id: block.id } })

    // Rename arguments in block configs and workflow outputs
    const nameChanged = block.name !== oldName
    if (oldName && block.name && workflow.value && nameChanged) {
      await Promise.all(
        workflow.value.workflowBlocks.map(async (wb) => {
          await renameReferencesInArgs(wb.blockConfig.arguments, oldName, block.name!)
        }),
      )
      await renameReferencesInArgs(workflow.value.result, oldName, block.name)
    }
  }

  async function renameReferencesInArgs(args: BlockConfigArgumentFragment[], oldName: string, newName: string) {
    // Rename argument in list if it exists and there is no argument with the new name
    return await Promise.all(
      args.map(async (arg) => {
        if (arg.argumentType === BlockConfigArgumentType.Reference && arg.value === oldName) {
          arg.value = newName
          await client.updateBlockConfigArgument({ input: { id: arg.id, value: newName } })
        }
      }),
    )
  }

  async function updateBlockConfig(
    config: BlockConfigDetailsFragment,
    update: {
      workflow?: WorkflowItemFragment | null
      block?: string
    },
  ) {
    Object.assign(config, update)
    const result = await client.updateBlockConfig({
      input: {
        workflow: config.workflow ? { id: config.workflow.id } : config.workflow,
        block: config.block,
        id: config.id,
      },
    })
    config.workflow = result.updateBlockConfig.workflow
  }

  async function deleteBlock(block: WorkflowBlockItemFragment) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    await client.deleteWorkflowBlock({ workflowBlockId: block.id })
    const index = workflow.value.workflowBlocks.findIndex((b) => b.id === block.id)
    if (index === -1) throw new Error('Block not found')
    workflow.value.workflowBlocks.splice(index, 1)
  }

  async function deleteConnection(from: WorkflowBlockItemFragment, to: { id: string }) {
    await client.removeNextBlock({ blockId: from.id, nextBlockId: to.id })
    for (const block of workflow.value?.workflowBlocks ?? []) {
      if (block.id === from.id) {
        block.nextBlocks = block.nextBlocks?.filter((b) => b.id !== to.id)
      } else if (block.id === to.id) {
        block.previousBlocks = block.previousBlocks?.filter((b) => b.id !== from.id)
      }
    }
  }

  async function connectBlocks(from: WorkflowBlockItemFragment, to: { id: string; condition?: string | null }) {
    const result = await client.updateWorkflowBlock({
      input: { id: from.id, nextBlocks: [{ id: to.id, condition: to.condition }] },
    })
    from.nextBlocks = result.updateWorkflowBlock.nextBlocks
    from.condition = result.updateWorkflowBlock.condition
    for (const block of workflow.value?.workflowBlocks ?? []) {
      if (block.id === to.id) {
        block.previousBlocks = block.previousBlocks?.filter((b) => b.id !== from.id) ?? []
        block.previousBlocks.push({ id: from.id, condition: from.condition })
      } else if (block.id === from.id) {
        block.nextBlocks = block.nextBlocks?.filter((b) => b.id !== to.id) ?? []
        block.nextBlocks.push({ id: to.id, condition: to.condition })
      }
    }
  }

  return {
    updateBlock,
    updateBlockConfig,
    deleteBlock,
    deleteConnection,
    connectBlocks,
  }
}
