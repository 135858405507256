<script lang="ts" setup>
import { LargeModalLayout } from '@/ui/components'
import { useBlockTypes } from '@/workflow-edit/composables'
import { Button, Column } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import BlockDocumentationArgument from './BlockDocumentationArgument.vue'

const { blockTypes } = useBlockTypes()

const props = defineProps<{
  blockId?: string
}>()

defineEmits<{
  close: []
}>()

const block = computed(() => blockTypes.value?.find((b) => b.id === props.blockId))
const title = computed(() => 'Block' + (block.value?.readableName ? ': ' + block.value?.readableName : ''))
</script>

<template>
  <LargeModalLayout :open="!!block" size-xl :title="title" @close="$emit('close')">
    <template #content>
      <Column gap="l">
        <Column gap="s">
          <h3>Functionality</h3>
          <p>{{ block?.description }}</p>
        </Column>

        <Column v-if="block?.arguments?.length" gap="m">
          <h3>Input</h3>
          <BlockDocumentationArgument v-for="(argument, i) of block.arguments" :key="i" :argument="argument" />
        </Column>

        <Column v-if="block?.result" gap="s">
          <h3>Output</h3>
          <BlockDocumentationArgument :argument="block.result" />
        </Column>
      </Column>
    </template>

    <template #footer="{ close }">
      <Button @click="close">Close</Button>
    </template>
  </LargeModalLayout>
</template>

<style module>
.argument {
  font-size: 14px;
  font-weight: 600;
  background-color: var(--dodo-color-primary);
  color: var(--grey-0-white);
  padding: 0 8px;
  border-radius: 4px;
}
</style>
