import { useApiClient } from '@/api'
import { type WorkflowDetailsFragment } from '@/generated/sdk'
import { ref } from 'vue'

export const workflow = ref<WorkflowDetailsFragment>()

export function useWorkflowDetails() {
  const { client } = useApiClient()

  async function loadWorkflow(workflowId: string) {
    if (workflowId === workflow.value?.id) return
    workflow.value = undefined
    const response = await client.workflowDetails({ id: workflowId })
    workflow.value = response.workflow[0]
  }

  async function updateWorkflow(update: {
    name?: string
    isPublic?: boolean
    cronSchedule?: string | null
    enableSchedule?: boolean
    description?: string
    editorState?: Record<string, unknown>
  }) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    Object.assign(workflow.value, update)
    const { updateWorkflow } = await client.updateWorkflow({ input: { ...update, id: workflow.value.id } })
    return updateWorkflow
  }

  async function deleteWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    await client.deleteWorkflow({ workflowId: workflow.value.id })
  }

  async function cloneWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    const result = await client.cloneWorkflow({ workflowId: workflow.value.id })
    return result.cloneWorkflow
  }

  return {
    workflow,
    loadWorkflow,
    updateWorkflow,
    deleteWorkflow,
    cloneWorkflow,
  }
}
