import type { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref } from 'vue'
import { useFieldTypeOptions, type FieldType } from './useFieldTypeOptions'
import { useWorkflowSettings } from './useWorkflowSettings'

const name = ref('')
const description = ref('')
const required = ref(false)
const type = ref<FieldType | null>(null)
const itemType = ref<FieldType | null>(null)
const data = reactive({ name, description, type, required, itemType })
const currentArg = ref<WorkflowInputArgumentFragment | null>(null)

export function useWorkflowSettingForm() {
  const { getInputFieldType, inputFieldOptions, setFieldType } = useFieldTypeOptions()
  const { workflowInputArguments } = useWorkflowSettings()

  function nameValidator(name?: string) {
    const nameTaken = !!workflowInputArguments?.value.some((a) => a.name === name && a.id !== currentArg.value?.id)
    if (nameTaken) return 'The title must be unique.'
    return undefined
  }

  function requiredIfArray(value?: unknown) {
    if (type.value === 'array' && !value) return 'This field is required.'
    return undefined
  }

  const selectedType = computed(() => inputFieldOptions.find((o) => o.value === type.value))
  const selectedItemType = computed(() => inputFieldOptions.find((o) => o.value === itemType.value))

  const { errors, validate } = useValidation({
    name: { value: name, required: true, maxLen: 50, validators: [nameValidator] },
    description: { value: description },
    type: { value: selectedType, required: true },
    arrayType: { value: selectedItemType, validators: [requiredIfArray] },
  })

  function reset() {
    name.value = ''
    description.value = ''
    type.value = null
    itemType.value = null
    required.value = false
  }

  function load(setting: WorkflowInputArgumentFragment) {
    // console.log('load', { ...setting })
    currentArg.value = setting
    name.value = setting.name
    description.value = setting.description
    type.value = getInputFieldType(setting)
    required.value = setting.required
    itemType.value = setting.items ? getInputFieldType(setting.items) : null
  }

  async function submit() {
    const isValid = await validate()
    if (!isValid) return false
    const arg = currentArg.value!
    arg.description = description.value
    arg.name = name.value
    arg.required = selectedType.value!.neverRequired ? false : data.required
    await setFieldType(arg, selectedType.value!.value, selectedItemType.value?.value)
    return true
  }

  return { data, load, submit, errors, reset, selectedType }
}
