<script setup lang="ts">
import { useApiClient } from '@/api'
import { links } from '@/router'
import { LargeModalLayout } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { useCurrency } from '@/ui/composables/useCurrency'
import { Button, Column, FormItem, Row, TextInput, useValidation } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useTopUpModal, useUserOrganization } from '../composables'

const { client } = useApiClient()
const { showMessage } = useSimpleMessage()
const router = useRouter()
const { formatCurrency, getCurrencySymbol } = useCurrency()

const currency = 'EUR'
const topupAmounts = [50, 100, 200]

const organization = useUserOrganization()
const { showTopUp } = useTopUpModal()

const topupAmount = ref<number | null>(null)
const customAmountOpen = ref(false)
const canAddCredits = ref(false)
const isConfirming = ref(false)

const { validate, errors } = useValidation({
  topupAmount: { value: topupAmount, validators: [topupAmountValidator] },
})

watch(showTopUp, reset, { immediate: true })

function reset() {
  topupAmount.value = null
  customAmountOpen.value = false
  isConfirming.value = false
}

function setTopupAmount(amount: number) {
  topupAmount.value = amount
  customAmountOpen.value = false
}

function toggleCustomAmount() {
  reset()
  customAmountOpen.value = !customAmountOpen.value
}

function handleCustomAmountUpdate(value: string | null) {
  topupAmount.value = value ? Number(value) : null
}

function topupAmountValidator(amount: number | null) {
  if (amount === null) return 'The amount is required'
  if (amount < 10) return `The minimum amount is ${formatCurrency(10)}`
  if (amount > 1000) return `The maximum amount is ${formatCurrency(1000)}`
}

async function handleAddCredits() {
  const valid = await validate()
  if (!valid) return

  const result = await client.canAddCredits()
  canAddCredits.value = result.canAddCredits
  isConfirming.value = true
}

async function submitAddCredits() {
  if (!topupAmount.value) return
  const amount = topupAmount.value
  await client.addCredits({ amount, currency })
  showMessage('Credits added to your account')
  showTopUp.value = false
  await organization.fetch()
}
</script>

<template>
  <LargeModalLayout :open="showTopUp" title="Buy credits" @close="showTopUp = false">
    <template #content>
      <FormItem label="Top-up amount">
        <Row gap="s">
          <Button
            v-for="amount in topupAmounts"
            :key="amount"
            :variant="!customAmountOpen && topupAmount === amount ? 'solid' : undefined"
            color="primary"
            @click="() => setTopupAmount(amount)"
          >
            {{ formatCurrency(amount) }}
          </Button>
          <Button :variant="customAmountOpen ? 'solid' : undefined" color="primary" @click="toggleCustomAmount">
            Custom
          </Button>
        </Row>
      </FormItem>
      <Row v-if="customAmountOpen" gap="s">
        <FormItem label="Custom amount" :error="errors.topupAmount">
          <Row gap="s">
            <Button disabled variant="solid" square>{{ getCurrencySymbol(currency) }}</Button>
            <TextInput
              :model-value="topupAmount?.toString()"
              type="number"
              placeholder="0"
              @update:model-value="handleCustomAmountUpdate"
            />
          </Row>
        </FormItem>
      </Row>
    </template>
    <template #footer>
      <Button :disabled="!topupAmount" color="primary" variant="solid" @click="handleAddCredits">Buy credits</Button>
    </template>
  </LargeModalLayout>

  <LargeModalLayout :open="isConfirming" title="Confirmation" @close="isConfirming = false">
    <template #content>
      <Column v-if="canAddCredits" gap="s">
        <p>Are you sure you want to add {{ formatCurrency(topupAmount ?? 0) }} credits to your account?</p>
        <p>The specified amount will be directly charged to your account.</p>
      </Column>
      <Column v-else gap="s">
        <p>You cannot add credits to your account.</p>
        <p>Edit your billing information to add credits.</p>
      </Column>
    </template>

    <template #footer>
      <Row v-if="canAddCredits" gap="s">
        <Button @click="isConfirming = false">Cancel</Button>
        <Button color="primary" variant="solid" @click="submitAddCredits">Confirm</Button>
      </Row>
      <Row v-else gap="s">
        <Button @click="showTopUp = false">Cancel</Button>
        <Button color="primary" variant="solid" @click="() => router.push(links.organization())">
          Billing information
        </Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>
