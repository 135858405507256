import { BlockArgumentType, type WorkflowInputArgumentFragment } from '@/generated/sdk'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { computed, ref } from 'vue'

export function useWorkflowApiCall() {
  const { workflow } = useWorkflowDetails()

  const apiKey = ref('')

  const workflowId = computed(() => workflow.value?.id ?? 'WORKFLOW_ID')
  const inputArgs = computed(() => workflow.value?.inputArguments ?? [])

  const argsString = computed(() => {
    if (inputArgs.value.length === 0) return 'null'
    return inputArgs.value.map((inputArg) => `"${inputArg.name}": ${placeholderValueFor(inputArg)}`).join(', ')
  })

  const curlCommand = computed(() => {
    const command = `curl https://api.twinai.nl/v1/workflows/${workflowId.value}/run \\`
    const post = '  -X POST \\'
    const contentType = '  -H "Content-Type: application/json" \\'
    const authorization = `  -H "Authorization: Bearer ${apiKey.value === '' ? 'YOUR_API_KEY' : apiKey.value}" \\`
    const data = `  -d '{"input": { ${argsString.value} }}'`
    return `${command}\n${post}\n${contentType}\n${authorization}\n${data}`
  })

  return { curlCommand, apiKey }
}

function placeholderValueFor(arg: WorkflowInputArgumentFragment) {
  switch (arg.argumentType) {
    case BlockArgumentType.Array:
      return '[]'
    case BlockArgumentType.Boolean:
      return 'true'
    case BlockArgumentType.File:
      return 'null'
    case BlockArgumentType.Integer:
      return '1'
    case BlockArgumentType.Number:
      return '1.1'
    case BlockArgumentType.String:
      return '"Example value"'
    case BlockArgumentType.Object:
      return '{}'
    default:
      return '?'
  }
}
