<script lang="ts" setup>
import { useApiClient } from '@/api'
import type { BlockConfigDetailsFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { useOpenRunBlockModal, useSimpleMessage } from '@/ui/composables'
import { useUserOrganization } from '@/user'
import { useBlockTypes } from '@/workflow-edit/composables'
import { BlockSettingsField } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Button, Row } from '@madxnl/dodo-ui'
import { computed, onMounted, ref, toRefs, watch } from 'vue'

const props = defineProps<{
  blockConfig?: BlockConfigDetailsFragment
  workflow: WorkflowDetailsFragment
}>()

const emits = defineEmits<{
  close: []
  submit: []
}>()

const { blockConfig: config, workflow } = toRefs(props)

const { open, closeRunBlockModal } = useOpenRunBlockModal()
const { showMessage } = useSimpleMessage()
const { getBlockType } = useBlockTypes()
const { client } = useApiClient()
const { fetch: fetchOrganization, insufficientFunds } = useUserOrganization()

onMounted(fetchOrganization)

const generatedFields = computed(() => {
  const fields: string[][] = []
  const blockType = getBlockType(config?.value?.block)
  const blockTypeArgs = blockType?.arguments || []
  const argsSeen = new Set<string>()
  for (const blockTypeArg of blockTypeArgs) {
    if (!blockTypeArg.name) continue
    fields.push([blockTypeArg.name])
    argsSeen.add(blockTypeArg.name)
  }
  for (const argument in inputData.value) {
    // Add custom arguments that are not defined in the block type
    if (argsSeen.has(argument)) continue
    fields.push([argument])
  }
  return fields
})

const inputData = ref<Record<string, unknown>>({})

watch(open, prepareInputData, { immediate: true })

async function prepareInputData() {
  if (!open.value || !config.value) return
  // const response = await client.getBlockConfigOutput({
  //   blockConfigId: config.value.id,
  //   queryArgs: { size: 1, page: 1 },
  // })
  inputData.value = {}
  for (const arg of config.value.arguments) {
    const value = arg.value
    inputData.value[arg.name] = JSON.parse(JSON.stringify(value))
  }
}

async function handleRunWorkflowBlock() {
  await client.runByBlockConfigId({
    blockConfigId: config.value!.id,
    input: inputData.value,
  })
  showMessage('Running block')
  closeRunBlockModal()
  emits('submit')
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run block" @close="closeRunBlockModal">
    <template #content>
      <template v-if="insufficientFunds">
        <h3>Insufficient funds</h3>
        <p>You don't have enough credits to run this block. Please top up your account to continue.</p>
      </template>

      <template v-else>
        <p v-if="generatedFields.length === 0">No block input arguments detected. Please add them in the editor.</p>
        <template v-else>
          <template v-for="(field, i) of generatedFields" :key="i">
            <BlockSettingsField
              :workflow="workflow"
              :config="config"
              :field="field"
              :input-data="inputData"
              :disabled="false"
              :block-item="getBlockType(config?.block)"
            />
          </template>
        </template>
        <Row justify="end">
          <Button color="primary" variant="solid" @click="handleRunWorkflowBlock">
            <TwinIcon icon="Play" />
            Run now
          </Button>
        </Row>
      </template>
    </template>
  </LargeModalLayout>
</template>
