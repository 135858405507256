<script setup lang="ts">
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { RunStatus } from '@/ui/components'
import { useEditorLinks } from '@/workflow-edit'
import { useBlockOutput } from '@/workflow-edit/sidebar-right/block-sidebar'
import { DataTableCell } from '@/workflows/datapage'
import { Button, Column, Row, Spinner } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const { linkReviewData } = useEditorLinks()
const { runs, fetchOutput } = useBlockOutput()

const props = defineProps<{
  workflowId: string
  workflowBlock: WorkflowBlockItemFragment
}>()

watch(() => props.workflowBlock.blockConfig.id, fetch, { immediate: true })

async function fetch() {
  await fetchOutput(props.workflowBlock.blockConfig.id)
}

async function clickReview() {
  await router.push(linkReviewData(props.workflowId, props.workflowBlock.id))
}
</script>

<template>
  <Spinner v-if="runs == null" />

  <Column v-else-if="runs.length === 0" justify="center" padding="m">
    <p class="form-description" style="text-align: center">No output items yet</p>
  </Column>

  <template v-else>
    <Button @click="clickReview">View block results</Button>

    <Column gap="m">
      <template v-for="run in runs" :key="run.id">
        <Row v-if="run.output == null" :class="$style.noOutput" padding="m" justify="between">
          <small class="no-interaction"><i>No output</i></small>
          <RunStatus v-if="run.status !== 'Done'" :run="run" />
        </Row>

        <DataTableCell v-else :value="run.output" :name="run.id" modal-title="Output" />
      </template>
    </Column>
  </template>
</template>

<style module>
.noOutput {
  background-color: var(--grey-1-altbg);
  border-radius: var(--card-radius);
  outline: 2px dashed var(--grey-2-lines);
  color: var(--grey-4-disabled);
  height: 56px;
}
</style>
