import { useApiClient } from '@/api'
import type { SubscriptionIntervalEnum, SubscriptionLevelEnum } from '@/generated/sdk'
import { PaymentMethodEnum } from '@/generated/sdk'
import { useChargebee } from '@/user'
import type { CardComponent } from '@chargebee/chargebee-js-vue-wrapper'
import { computed, reactive, ref } from 'vue'

type PaymentIntentInput = {
  subscriptionLevel?: SubscriptionLevelEnum
  subscriptionInterval: SubscriptionIntervalEnum
  paymentMethod: PaymentMethodEnum
  cardName?: string
}

const creditcardComponent = ref<typeof CardComponent>(null)
const fieldsCompleted = reactive({ number: false, expiry: false, cvv: false })

export function useChargebeePayment() {
  const api = useApiClient()
  const { chargebeeIsLoading, getChargebeeInstance } = useChargebee()

  const validCreditCard = computed(() => {
    // Check if statusses of CreditCardFields are valid
    return fieldsCompleted.cvv && fieldsCompleted.expiry && fieldsCompleted.number
  })

  async function createPaymentIntent(data: PaymentIntentInput): Promise<unknown> {
    if (!data.subscriptionLevel) return

    const { createChargebeePaymentIntent: paymentIntent } = await api.client.createChargebeePaymentIntent({
      subscriptionLevel: data.subscriptionLevel,
      subscriptionInterval: data.subscriptionInterval,
      paymentMethod: data.paymentMethod,
    })
    return paymentIntent
  }

  async function authorizeCreditCardPayment(data: PaymentIntentInput) {
    const paymentIntent = await createPaymentIntent(data)
    const authorizedIntent = await creditcardComponent.value.authorizeWith3ds(paymentIntent, {
      billingAddress: {
        lastName: data.cardName,
      },
    })
    return authorizedIntent.id
  }

  async function authorizeIDealMethod(data: PaymentIntentInput) {
    const instance = await getChargebeeInstance()
    const authorizedIntent = await instance.handlePayment('ideal', {
      paymentIntent: async () => await createPaymentIntent(data),
    })
    return authorizedIntent.id
  }

  async function getAuthorizedPaymentIntentId(data: PaymentIntentInput) {
    if (data.paymentMethod === PaymentMethodEnum.CreditCard) {
      return await authorizeCreditCardPayment(data)
    } else {
      return await authorizeIDealMethod(data)
    }
  }

  return {
    chargebeeIsLoading,
    getAuthorizedPaymentIntentId,
    creditcardComponent,
    fieldsCompleted,
    validCreditCard,
  }
}
