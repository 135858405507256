<script setup lang="ts">
import type { BlockItemFragment } from '@/generated/sdk'
import { SearchableList } from '@/ui/components'
import { Dropdown } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import { useBlockTypes } from '../composables'

const { blockTypes } = useBlockTypes()

const open = defineModel<boolean>('open')
const emit = defineEmits<{
  selectBlockType: [blockItem: BlockItemFragment]
}>()

watch(open, () => (!open.value ? reset() : null), { immediate: true })

const listOptions = computed(() => {
  if (!blockTypes.value) return
  const items = blockTypes.value
    .map((blockType) => ({
      name: blockType.readableName,
      id: blockType.id,
      blockType: blockType.id,
      icon: blockType.icon,
    }))
    .sort((a, b) => {
      if (a.icon === b.icon) {
        return a.name.localeCompare(b.name)
      }
      return a.icon.localeCompare(b.icon)
    })
  return [
    {
      groupName: null,
      items,
    },
  ]
})

function reset() {
  open.value = false
}

function selectBlockType(blockItemId: string) {
  const blockType = blockTypes.value?.find((blockType) => blockType.id === blockItemId)
  if (!blockType) return
  emit('selectBlockType', blockType)
  reset()
}
</script>

<template>
  <Dropdown v-model:open="open" @pointerdown.stop>
    <template #trigger><slot /></template>
    <template #content>
      <SearchableList
        v-if="open"
        header="Select block type"
        :grouped-options="listOptions"
        @close="reset"
        @select="selectBlockType"
      />
    </template>
  </Dropdown>
</template>
