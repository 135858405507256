import type { WorkflowBlockItemFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { computed, type Ref } from 'vue'

export type DataSource = {
  label: string
  value: string
  block?: string
}

export function useDataSources(args: {
  workflow: Ref<WorkflowDetailsFragment | undefined>
  workflowBlock: Ref<WorkflowBlockItemFragment | null | undefined>
}) {
  const { workflow, workflowBlock } = args

  function getBlock(blockId: string) {
    return workflow.value?.workflowBlocks.find((block) => block.id === blockId) ?? null
  }

  function getPreviousBlocks(): WorkflowBlockItemFragment[] {
    if (!workflowBlock.value) return []

    // Recursively get all previous blocks
    const parentBlocks: WorkflowBlockItemFragment[] = []
    const unvisitedParents = [...(workflowBlock.value.previousBlocks ?? [])]
    while (unvisitedParents.length) {
      const { id } = unvisitedParents.pop()!
      const block = getBlock(id)
      if (!block || parentBlocks.some((b) => b.id === id)) continue // Not found or already visited
      parentBlocks.push(block)
      unvisitedParents.push(...(block.previousBlocks ?? []))
    }
    return parentBlocks
  }

  const availableDataSources = computed(() => {
    // If no id is provided, assume that all blocks are an option
    const blockOutputs: DataSource[] = []
    const allWorkflowBlocks = workflow.value?.workflowBlocks || []
    const workflowBlocks = workflowBlock.value ? getPreviousBlocks() : allWorkflowBlocks
    for (const wb of workflowBlocks) {
      const name = wb.resultName ?? wb.name ?? ''
      // Add entries for named blocks
      if (name) {
        const block = wb.blockConfig.block
        blockOutputs.push({ label: name, value: name, block })
      }
    }
    const isInsideLoop = workflowBlock.value?.blockConfig?.loop
    if (isInsideLoop) {
      // Add current item as an option
      blockOutputs.push({ label: 'Current item (Loop)', value: 'entry' })
    }

    const workflowInputs: DataSource[] =
      workflow.value?.inputArguments.map(({ name }) => ({ label: name, value: name })) || []

    return [
      { groupName: 'Workflow input', references: workflowInputs },
      { groupName: 'Block output', references: blockOutputs },
    ]
  })

  const allReferences = computed(() => availableDataSources.value.flatMap(({ references }) => references))

  return { availableDataSources, allReferences }
}
