<script setup lang="ts">
import { useApiClient } from '@/api'
import type { FileDetailsFragment } from '@/generated/sdk'
import { FileUploadModal, TwinIcon } from '@/ui/components'
import { Button, Icon, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'

const { client } = useApiClient()

const props = defineProps<{
  multiple?: boolean
  modelValue: undefined | null | string | string[]
  placeholder?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: null | string | string[]]
}>()

const ids = computed(() => {
  const values = Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
  return values.filter((x) => typeof x === 'string' && x !== 'null' && x !== 'undefined' && x !== '') as string[]
})

const fileDetails = ref<FileDetailsFragment[]>([])
const fileuploadModalOpen = ref(false)

const displayedString = computed(() => {
  const fileNames = ids.value.map((id) => fileDetails.value.find((f) => f.id === id)?.originalName)
  return fileNames.filter((x) => x).join(', ')
})

watch(ids, fetchFileDetails, { immediate: true })

async function fetchFileDetails() {
  // Fetch only missing file details and add them to the list
  const missingFileDetails = ids.value.filter((id) => !fileDetails.value.find((f) => f.id === id))
  if (missingFileDetails.length > 0) {
    const response = await client.files({ input: missingFileDetails.map((id) => ({ id })) })
    fileDetails.value.push(...response.file)
  }
}

async function setFileIds(newFiles: FileDetailsFragment[]) {
  if (newFiles.length === 0) return
  fileDetails.value.push(...newFiles)
  fileuploadModalOpen.value = false
  const ids = newFiles.map((f) => f.id)
  emitValue(ids)
}

function clearFiles() {
  emitValue([])
}

function emitValue(ids: string[]) {
  const value = props.multiple ? ids : ids?.[0]
  emit('update:modelValue', value || null)
}
</script>

<template>
  <Row gap="xs">
    <TextInput
      :class="$style.fileInput"
      :model-value="displayedString"
      :placeholder="placeholder || `Select file${multiple ? 's' : ''}`"
      :disabled="disabled"
      @click="fileuploadModalOpen = true"
    />
    <Button
      v-if="ids.length"
      square
      :disabled="disabled"
      variant="clear"
      :title="`Clear file${multiple ? 's' : ''}`"
      @click="clearFiles"
    >
      <TwinIcon icon="Cross" />
    </Button>
    <Button
      v-else
      square
      variant="clear"
      :disabled="disabled"
      :title="`Select file${multiple ? 's' : ''}`"
      @click="fileuploadModalOpen = true"
    >
      <Icon name="Folder_Open" />
    </Button>
  </Row>

  <FileUploadModal
    :multiple="multiple ?? false"
    :open="fileuploadModalOpen"
    @close="fileuploadModalOpen = false"
    @files-created="setFileIds"
  />
</template>

<style module>
.fileInput {
  flex: 1;
  cursor: pointer;
}
</style>
