<script setup lang="ts">
import { useTopUpModal } from '@/user/composables'
import { Button, Row } from '@madxnl/dodo-ui'

const { showTopUp, outOfFunds } = useTopUpModal()

function clickTopUp() {
  showTopUp.value = true
}
</script>

<template>
  <Row v-if="outOfFunds" :class="$style.banner" justify="between">
    <h4 style="color: inherit">You are out of funds. Please top up your account to continue using the service.</h4>
    <Button @click="clickTopUp">Top up</Button>
  </Row>
</template>

<style module>
.banner {
  padding: var(--dodo-spacing-m) var(--dodo-spacing-l);
  background-color: var(--dodo-color-danger);
  color: var(--grey-0-white);
}
</style>
