import { SubscriptionLevelEnum } from '@/generated/sdk'

const availablePlans = [
  {
    id: 'simple',
    subscriptionLevel: SubscriptionLevelEnum.Simple,
    name: 'Simple',
    priceMonthly: 'Free',
    priceYearly: 'Free',
    description: 'Free plan for all users.',
    callToAction: 'Get started for free',
    features: ['1 workflow', '€ 0,50 monthly AI budget', '1 user'],
  },
  {
    id: 'starter',
    subscriptionLevel: SubscriptionLevelEnum.Starter,
    name: 'Starter',
    priceMonthly: '€350,-',
    priceYearly: '€3500,-',
    description: 'First automation.',
    callToAction: 'Get Starter plan',
    features: ['3 workflows', '€ 50,- monthly AI budget', '1 user', "Custom LLM's", '1 GB storage'],
  },
  {
    id: 'pro',
    subscriptionLevel: SubscriptionLevelEnum.Pro,
    name: 'Pro',
    priceMonthly: '€650,-',
    priceYearly: '€6500,-',
    description: "For SME's.",
    callToAction: 'Get Pro plan',
    features: [
      '10 workflows',
      '€125,- monthly AI budget',
      '5 users',
      "Custom LLM's",
      'On call support',
      '5 GB storage',
    ],
  },
  {
    id: 'enterprise',
    subscriptionLevel: SubscriptionLevelEnum.Enterprise,
    name: 'Enterprise',
    priceMonthly: 'Custom',
    priceYearly: 'Custom',
    description: 'When you have custom software needs.',
    callToAction: 'Get Enterprise plan',
    features: ['Everything in pro plan', 'Custom software development', 'Custom AI budget'],
  },
]

export function useAvailablePlans() {
  // In future we can fetch this from an API

  return { availablePlans }
}
