import { useApiClient } from '@/api'
import type { UserDetailsFragment } from '@/generated/sdk'
import { getCurrentScope } from '@sentry/vue'
import { computed, ref } from 'vue'
import { useAuthSession } from './useAuthSession'

const currentUser = ref<UserDetailsFragment | null>(null)

export function useCurrentUser() {
  const api = useApiClient()
  const { isAuthenticated } = useAuthSession()

  function displayName(maxLength = 16) {
    // Returns a shortened version of the user's name, or their email if no name is available
    if (!currentUser.value) return ''
    const { firstName, lastName, email } = currentUser.value
    const name = firstName || lastName || email
    return name.length > maxLength ? name.slice(0, maxLength) + '…' : name
  }

  const fullName = computed(() => {
    if (!currentUser.value) return ''
    const { firstName, lastName } = currentUser.value
    return `${firstName || ''} ${lastName || ''}`.trim()
  })

  async function updateUser() {
    if (isAuthenticated.value) {
      // Prevent multiple concurrent requests
      currentUser.value = await getUser()
    } else {
      currentUser.value = null
    }
    updateSentryScope()
  }

  function updateSentryScope() {
    // Update Sentry user context
    const scope = getCurrentScope()
    if (currentUser.value) {
      const { id, email } = currentUser.value
      scope.setUser({ id, email, username: email })
    } else {
      scope.setUser(null)
    }
  }

  async function getUser() {
    const response = await api.client.me()
    return response.me
  }

  return { currentUser, displayName, fullName, updateUser }
}
