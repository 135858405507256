<script lang="ts" setup>
import { onMounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useSetPasswordForm } from '../composables'

import { links } from '@/router'
import { InlineMessage } from '@/ui/components'
import { Button, Column, Form, FormItem, TextInput } from '@madxnl/dodo-ui'
import AuthPageLayout from './AuthPageLayout.vue'

const route = useRoute()
const { data, errors, submit, submitMessage } = useSetPasswordForm()

onMounted(() => {
  data.email = route.query.email as string
  data.token = route.query.token as string
})
</script>

<template>
  <AuthPageLayout title="Set new password">
    <template v-if="submitMessage">
      <InlineMessage :color="submitMessage.code">
        {{ submitMessage.message }}
      </InlineMessage>
    </template>

    <Column v-if="submitMessage" gap="l">
      <p v-if="submitMessage.code === 'success'">You can now log in with your new password.</p>
      <p v-else>Go back to the login page to try again.</p>

      <RouterLink :to="links.login()">
        <Button variant="solid" color="primary" style="width: 100%">Proceed to log in</Button>
      </RouterLink>
    </Column>

    <Form v-else @submit="submit">
      <Column gap="l">
        <FormItem label="New password" :error="errors.password">
          <TextInput id="password" v-model="data.password" type="password" />
        </FormItem>
        <Button type="submit" variant="solid" color="primary">Save</Button>
      </Column>
    </Form>
  </AuthPageLayout>
</template>
