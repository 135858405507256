<script setup lang="ts">
import { CheckMark, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { useWorkflowDetails } from '@/workflow-edit'
import { Button, Column, Dropdown, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { useWorkflowScheduledRun } from './composables/useWorkflowScheduledRun'

const { workflow } = useWorkflowDetails()
const { showMessage } = useSimpleMessage()

const {
  cronSchedule,
  enableSchedule,
  repeatUnit,
  repeatEvery,
  repeatTime,
  repeatWeekdays,
  repeatMonthDays,
  repeatUnitOptions,
  weekdays,
  monthDays,
  repeatMinutes,
  repeatHours,
  errors,
  readableCron,
  extractMinutes,
  extractHours,
} = useWorkflowScheduledRun()

const advancedModeEnforced = computed(() => repeatUnit.value === 'advanced')
const enableAdvanced = ref(advancedModeEnforced.value)

watch(repeatUnit, (value) => {
  if (value === 'advanced') enableAdvanced.value = true
})

function toggleScheduledRun() {
  enableSchedule.value = !enableSchedule.value
  if (enableSchedule.value) showMessage('Scheduled run enabled')
  else showMessage('Scheduled run disabled')
}

function toggleAdvanced() {
  if (enableAdvanced.value && advancedModeEnforced.value) return
  enableAdvanced.value = !enableAdvanced.value
}

function toggleRepeatWeekday(dayNumber: number) {
  const selected = [...repeatWeekdays.value]
  if (selected.includes(dayNumber)) selected.splice(selected.indexOf(dayNumber), 1)
  else selected.push(dayNumber)
  cronSchedule.value = `0 0 * * ${selected.join(',')}`
}

function toggleRepeatMonthDay(dayNumber: number) {
  const selected = [...repeatMonthDays.value]
  if (selected.includes(dayNumber)) selected.splice(selected.indexOf(dayNumber), 1)
  else selected.push(dayNumber)
  cronSchedule.value = `0 0 ${selected.join(',')} */${repeatEvery.value} *`
}

function changeRepeatUnit(value: string) {
  if (value === 'minute') cronSchedule.value = '*/1 * * * *'
  else if (value === 'hour') cronSchedule.value = '0 */1 * * *'
  else if (value === 'day') cronSchedule.value = '0 0 */1 * *'
  else if (value === 'week') cronSchedule.value = '0 0 * * 1'
  else if (value === 'month') cronSchedule.value = '0 0 1 */1 *'
  return true
}

function changeRepeatEvery(value: number) {
  if (repeatUnit.value === 'minute') cronSchedule.value = `*/${value} * * * *`
  else if (repeatUnit.value === 'hour') cronSchedule.value = `0 */${value} * * *`
  else if (repeatUnit.value === 'day') cronSchedule.value = `${repeatMinutes.value} ${repeatHours.value} */${value} * *`
  else if (repeatUnit.value === 'month')
    cronSchedule.value = `${repeatMinutes.value} ${repeatHours.value} ${repeatMonthDays.value.join(',')} */${value} *`
}

function changeRepeatTime(value: string) {
  const minutes = extractMinutes(value)
  const hours = extractHours(value)
  if (repeatUnit.value === 'day') cronSchedule.value = `${minutes} ${hours} */${repeatEvery.value} * *`
  else if (repeatUnit.value === 'week') cronSchedule.value = `${minutes} ${hours} * * ${repeatWeekdays.value.join(',')}`
  else if (repeatUnit.value === 'month')
    cronSchedule.value = `${minutes} ${hours} ${repeatMonthDays.value.join(',')} */${repeatEvery.value} *`
}
</script>

<template>
  <FormItem label="Scheduled run">
    <CheckMark :disabled="!workflow?.draft" :model-value="!!enableSchedule" @update:model-value="toggleScheduledRun">
      Enable scheduled run
    </CheckMark>
  </FormItem>

  <Dropdown v-if="enableSchedule">
    <template #trigger="{ toggle }">
      <Button v-if="workflow?.draft" @click="toggle">
        <TwinIcon icon="Settings" />
        Settings
      </Button>
      <p v-else class="form-description">
        {{ readableCron }}
      </p>
    </template>
    <template #content>
      <Column style="width: 568px">
        <Row padding="0" justify="between">
          <Row grow>
            <h3>Schedule</h3>
          </Row>

          <CheckMark
            :disabled="advancedModeEnforced"
            :model-value="enableAdvanced"
            @update:model-value="toggleAdvanced"
          >
            Advanced
          </CheckMark>
        </Row>

        <template v-if="enableAdvanced">
          <Column style="width: 568px">
            <FormItem label="Cron expression" :error="errors.cronSchedule">
              <Row>
                <TextInput v-model="cronSchedule" style="flex: 1" :error="errors.cronSchedule" />
                <Button @click="changeRepeatUnit('hour') && (enableAdvanced = false)">
                  <TwinIcon icon="Undo" />
                  Reset
                </Button>
              </Row>
            </FormItem>

            <p class="form-description">
              {{ readableCron }}
            </p>
          </Column>
        </template>

        <template v-else>
          <FormItem label="Repeat">
            <Select :model-value="repeatUnit" :options="repeatUnitOptions" @update:model-value="changeRepeatUnit" />
          </FormItem>

          <FormItem v-if="repeatUnit === 'week'" label="Repeat on">
            <Row>
              <Button
                v-for="(value, key) in weekdays"
                :key="key"
                :variant="repeatWeekdays.includes(Number(key)) ? 'solid' : undefined"
                round
                color="primary"
                @click="toggleRepeatWeekday(Number(key))"
              >
                {{ value }}
              </Button>
            </Row>
          </FormItem>

          <FormItem
            v-if="repeatUnit === 'month'"
            :label="repeatMonthDays.length > 1 ? 'Repeat on days' : 'Repeat on day'"
          >
            <Row :class="$style.repeatDays">
              <Button
                v-for="day in monthDays"
                :key="day"
                round
                :variant="repeatMonthDays.includes(day) ? 'solid' : undefined"
                color="primary"
                @click="toggleRepeatMonthDay(day)"
              >
                {{ day }}
              </Button>
            </Row>
          </FormItem>

          <Row gap="l">
            <FormItem v-if="repeatUnit !== 'week'" label="Repeat every" style="width: 100%">
              <Row>
                <TextInput
                  :model-value="repeatEvery?.toString()"
                  class="dodo-formfield dodo-textinput-input"
                  style="flex: 1"
                  type="number"
                  min="1"
                  :max="repeatUnit === 'minute' ? 59 : repeatUnit === 'hour' ? 23 : repeatUnit === 'day' ? 31 : 12"
                  @update:model-value="changeRepeatEvery(Number($event))"
                />
                <p>
                  {{ repeatEvery === 1 ? repeatUnit : `${repeatUnit}s` }}
                </p>
              </Row>
            </FormItem>

            <FormItem v-if="repeatUnit !== 'hour' && repeatUnit !== 'minute'" label="Repeat at" style="width: 100%">
              <input
                :value="repeatTime"
                class="dodo-formfield dodo-textinput-input"
                type="time"
                @change="(e) => changeRepeatTime((e.target as HTMLInputElement).value)"
              />
            </FormItem>
          </Row>

          <p class="form-description">
            {{ readableCron }}
          </p>
        </template>
      </Column>
    </template>
  </Dropdown>
</template>

<style module>
.repeatDays {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
}
</style>
