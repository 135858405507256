<script lang="ts" setup>
import { PaymentMethodEnum } from '@/generated/sdk'
import { ControlWithLogo } from '@/ui/components'
import { CardComponent, CardCvv, CardExpiry, CardNumber } from '@chargebee/chargebee-js-vue-wrapper'
import { Column, FormItem, TextInput } from '@madxnl/dodo-ui'
import { reactive } from 'vue'
import { useChargebeePayment, useSignupForm } from '../composables'

const { IDeal, CreditCard } = PaymentMethodEnum
const sortedMethods = [
  { label: 'iDEAL', value: IDeal },
  { label: 'Credit card', value: CreditCard },
]

const { data, errors } = useSignupForm()
const { chargebeeIsLoading, creditcardComponent, fieldsCompleted } = useChargebeePayment()

type Status = {
  field: 'number' | 'expiry' | 'cvv'
  complete: boolean
  error: { message: string } | undefined
}

const styles = {
  base: {
    color: '#1F2933',
    fontWeight: '400',
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: '15px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#fff',
    },
  },
  invalid: {
    color: '#1F2933',
  },
}
const classes = {
  focus: 'focus',
  complete: 'complete',
  invalid: 'invalid',
  empty: 'empty',
}

const creditcardErrors = reactive({ number: '', expiry: '', cvv: '' })

function onChange(status: Status): void {
  for (const field of Object.keys(creditcardErrors)) {
    if (field === status.field) creditcardErrors[field] = status.error?.message ?? ''
  }

  for (const field of Object.keys(fieldsCompleted)) {
    if (field === status.field) fieldsCompleted[field] = status.complete
  }
}

function changePaymentMethod(method: PaymentMethodEnum): void {
  data.paymentMethod = method
  // Reset errors
  creditcardErrors.number = ''
  creditcardErrors.expiry = ''
  creditcardErrors.cvv = ''
}
</script>

<template>
  <Column gap="l">
    <h3>Payment method</h3>
    <Column>
      <ControlWithLogo :options="sortedMethods" :selected="data.paymentMethod" @update:selected="changePaymentMethod">
        <template v-if="data.paymentMethod === CreditCard">
          <p v-if="chargebeeIsLoading">Loading...</p>

          <CardComponent
            v-else
            ref="creditcardComponent"
            style="width: 100%"
            :styles="styles"
            :classes="classes"
            @change="onChange"
          >
            <Column gap="l" grow>
              <FormItem label="Name on card" :error="errors.cardName">
                <TextInput v-model="data.cardName" name="cardName" autofocus />
              </FormItem>

              <FormItem label="Card number" :error="creditcardErrors.number">
                <CardNumber name="card-number" class="dodo-formfield dodo-input chargebee-input" />
              </FormItem>
              <FormItem label="Expiry date" :error="creditcardErrors.expiry">
                <CardExpiry name="card-expiry" class="dodo-formfield dodo-input chargebee-input" />
              </FormItem>
              <FormItem label="Security code" :error="creditcardErrors.cvv">
                <CardCvv name="card-cvv" class="dodo-formfield dodo-input chargebee-input" />
              </FormItem>
            </Column>
          </CardComponent>
        </template>
      </ControlWithLogo>
    </Column>
  </Column>
</template>
