import { reactive, readonly } from 'vue'

const state = reactive({
  open: false,
  name: null as string | null | undefined,
  type: '',
  resolve: null as null | ((value: boolean) => void),
  superSecure: false,
})

const currentDialog = readonly(state)

export function useConfirmDelete() {
  // This service is used to show a dialog with a title, message and a list of actions

  async function confirmDelete(options: { name?: string | null; type: string; superSecure?: boolean }) {
    if (state.open) return
    state.name = options.name
    state.type = options.type
    state.superSecure = options.superSecure ?? false
    state.open = true
    const result = await new Promise((resolve) => (state.resolve = resolve))
    state.open = false
    return result
  }

  return {
    confirmDelete,
    currentDialog,
  }
}
