import { useApiClient } from '@/api'
import { type LoginMutationVariables } from '@/generated/sdk'
import { defineComponent, inject, onErrorCaptured, provide, ref, type InjectionKey } from 'vue'

type Tokens = {
  token: string
  refreshToken: string
  tokenExpirationDateUTC: string
  refreshExpirationDateUTC: string
}

const key: InjectionKey<ReturnType<typeof newAuthSession>> = Symbol('authSession')

// Component that provides the auth session to its children
export const ProvideAuthSession = defineComponent({
  setup() {
    provide(key, newAuthSession())
  },
  render() {
    return this.$slots.default?.()
  },
})

export function useAuthSession() {
  // Use the auth session in a component
  return inject(key)!
}

export function newAuthSession() {
  const api = useApiClient()
  const tokenStorageKey = 'auth-session'
  const refreshTimerInterval = 5000
  const broadcast = new BroadcastChannel('auth-channel')

  const initPromise = ref<Promise<void>>()
  const isAuthenticated = ref<boolean>(false)
  const refreshTimer = ref<ReturnType<typeof setTimeout>>()
  const currentToken = ref<Tokens>()

  onErrorCaptured((err) => {
    if (err instanceof Error && err.message.startsWith('Forbidden resource:')) {
      logoutAuth()
      return false
    }
    return true // propagate the error
  })

  broadcast.addEventListener('message', onMessage)

  // Set up the refresh timer once
  refreshTimer.value ??= setInterval(scheduledRefresh, refreshTimerInterval)
  initPromise.value = initPromise.value ?? loadAuthAsync()

  async function loadAuthSession() {
    // Wait for the auth session to be loaded, if it's not already
    await initPromise.value
  }

  async function loadAuthAsync() {
    // Restore the auth session from the storage
    const tokens = parseStoredTokens()
    if (tokens) {
      setTokens(tokens)
      await refreshIfNeeded()
    } else {
      logoutAuth()
    }
  }

  function scheduledRefresh() {
    // Every interval try refreshing if needed
    if (!isAuthenticated.value) return
    refreshIfNeeded().catch(() => {})
  }

  async function refreshIfNeeded() {
    if (!currentToken.value) return
    // Refresh token if it is about to expire in the next interval
    const { tokenExpirationDateUTC, refreshToken, refreshExpirationDateUTC } = currentToken.value
    const refreshExpirationTime = new Date(refreshExpirationDateUTC).getTime()
    const timeUntilRefreshExpire = refreshExpirationTime - Date.now()
    const refreshExpired = timeUntilRefreshExpire < 1000
    if (refreshExpired) {
      logoutAuth()
      return
    }
    const expirationTime = new Date(tokenExpirationDateUTC).getTime()
    const timeUntilExpire = expirationTime - Date.now()
    if (timeUntilExpire < refreshTimerInterval + 1000) {
      try {
        const response = await api.client.refresh({ refreshToken })
        setTokens(response.refresh)
      } catch (e) {
        logoutAuth()
        return
      }
    }
  }

  async function login(data: LoginMutationVariables) {
    const response = await api.client.login(data)
    setTokens(response.login)
  }

  function logoutAuth() {
    // Sign out from the API
    if (isAuthenticated.value) {
      isAuthenticated.value = false
      api.client.logout().catch(() => {})
      api.setAccessToken('')
      delete currentToken.value
      localStorage.removeItem(tokenStorageKey)
      broadcast.postMessage({ type: 'logout' })
    }
  }

  function parseStoredTokens() {
    const json = localStorage.getItem(tokenStorageKey)
    const tokens = json ? tryParse(json) : null
    if (tokens == null) return null
    type Key = keyof typeof tokens
    const token = String(tokens['token' as Key])
    const refreshToken = String(tokens['refreshToken' as Key])
    const tokenExpirationDateUTC = String(tokens['tokenExpirationDateUTC' as Key])
    const refreshExpirationDateUTC = String(tokens['refreshExpirationDateUTC' as Key])
    if (new Date(refreshExpirationDateUTC).getTime() < Date.now()) return null
    return { token, refreshToken, tokenExpirationDateUTC, refreshExpirationDateUTC }
  }

  function setTokens(token: Tokens) {
    // Do not set the token if it is same or older than the current one
    const currentExpiryUTC = currentToken.value?.tokenExpirationDateUTC
    if (currentExpiryUTC && token.tokenExpirationDateUTC.localeCompare(currentExpiryUTC) <= 0) return

    api.setAccessToken(token.token)
    currentToken.value = { ...token }
    localStorage.setItem(tokenStorageKey, JSON.stringify(currentToken.value))
    isAuthenticated.value = true
    broadcast.postMessage({ type: 'login', token })
  }

  function onMessage(event: MessageEvent) {
    if (event.data.type === 'login') {
      setTokens(event.data.token)
    } else if (event.data.type === 'logout') {
      logoutAuth()
    }
  }

  function tryParse(value: string): unknown {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  return {
    login,
    logoutAuth,
    isAuthenticated,
    loadAuthSession,
  }
}
