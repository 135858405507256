<script setup lang="ts">
import { MainPageLayout } from '@/navigation/components'
import { Card, Column, Tabs } from '@madxnl/dodo-ui'
import { computed, onMounted, ref } from 'vue'
import { ChargebeeInvoices, OrganizationClients, OrganizationForm, OrganizationMembers } from '.'
import { useUserOrganization } from '../composables'
import OrganizationApi from './OrganizationApi.vue'
import OrganizationBilling from './OrganizationBilling.vue'
import OrganizationIntegrations from './OrganizationIntegrations.vue'

const { organization, fetch } = useUserOrganization()

const basicTabs = [
  { name: 'General', slot: 'settings' },
  { name: 'Subscription', slot: 'subscription' },
  { name: 'Members', slot: 'members' },
  { name: 'API Keys', slot: 'api' },
  { name: 'Integrations', slot: 'integrations' },
]

const tabs = computed(() => {
  if (!organization.value) return []
  if (organization.value.organizations.length > 0) {
    return [...basicTabs, { name: 'Clients', slot: 'clients' }]
  }
  return basicTabs
})

const tabIndex = ref(0)

onMounted(async () => {
  await fetch()
  const urlParams = new URLSearchParams(location.search)
  const page = urlParams.get('page')
  if (page) {
    const tab = basicTabs.find((tab) => tab.slot === page)
    if (tab) {
      tabIndex.value = basicTabs.indexOf(tab)
    }
  }
})
</script>

<template>
  <MainPageLayout>
    <h1>Your organization</h1>

    <Tabs v-model:tab-index="tabIndex" :tabs="tabs" gap="l">
      <template #settings>
        <Column gap="l" :class="$style.tabContainer">
          <Card gap="m">
            <h3>General information</h3>
            <OrganizationForm />
          </Card>
        </Column>
      </template>

      <template #subscription>
        <Column gap="l" :class="$style.tabContainer">
          <Card gap="m">
            <h3>Subscription</h3>
            <ChargebeeInvoices />
          </Card>
          <Card gap="m">
            <h3>Billing address</h3>
            <OrganizationBilling />
          </Card>
        </Column>
      </template>

      <template #members>
        <Column gap="l" :class="$style.tabContainer">
          <OrganizationMembers />
        </Column>
      </template>

      <template #api>
        <Column gap="l" :class="$style.tabContainer">
          <OrganizationApi />
        </Column>
      </template>

      <template #integrations>
        <Column gap="l" :class="$style.tabContainer">
          <h3>Available integrations</h3>
          <OrganizationIntegrations />
        </Column>
      </template>

      <template #clients>
        <Column gap="l" :class="$style.tabContainer">
          <OrganizationClients />
        </Column>
      </template>
    </Tabs>
  </MainPageLayout>
</template>

<style module>
.tabContainer {
  padding: var(--dodo-spacing-l) 0;
}
</style>
