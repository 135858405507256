<script lang="ts" setup>
import { links } from '@/router'
import { LargeModalLayout } from '@/ui/components'
import { useUserOrganization } from '@/user/composables'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import RunWorkflowForm from './RunWorkflowForm.vue'

const router = useRouter()
const props = defineProps<{
  workflowId: string
  open: boolean
}>()

const { fetch: fetchOrganization, insufficientFunds } = useUserOrganization()

defineEmits<{
  close: []
}>()

onMounted(fetchOrganization)

async function handleRunWorkflow() {
  await router.push(links.workflowData(props.workflowId))
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run workflow" @close="$emit('close')">
    <template #content>
      <template v-if="insufficientFunds">
        <h3>Insufficient funds</h3>
        <p>You don't have enough credits to run this workflow. Please top up your account to continue.</p>
      </template>

      <RunWorkflowForm v-else :workflow-id="workflowId" use-input-data @running="handleRunWorkflow" />
    </template>
  </LargeModalLayout>
</template>
