<script setup lang="ts">
import { useApiClient } from '@/api'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { Card, Row, Spinner } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'

const props = defineProps<{
  value: unknown
  name: string
  modalTitle: string
}>()

const { downloadFile, endpoint } = useApiClient()

const showModal = ref(false)
const loading = ref(false)
const valueString = computed(() => {
  const value = props.value
  if (typeof value === 'string') return value
  if (isFile.value) return fileOriginalName.value
  if (value == null || typeof value !== 'object') return JSON.stringify(value)
  if (Array.isArray(value)) return JSON.stringify(value, null, 2)

  const flattened = Object.entries(value).reduce(
    (acc, [key, v]) => {
      acc[key] = typeof v === 'object' ? JSON.stringify(v) : v
      return acc
    },
    {} as Record<string, string>,
  )
  return JSON.stringify(flattened, null, 2)
})

const isFile = computed(() => {
  const value = props.value
  return value && typeof value === 'object' && '_modelName' in value && value._modelName === 'file'
})

const fileOriginalName = computed(() => {
  const value = props.value
  return value && typeof value === 'object' && 'originalName' in value ? (value.originalName as string) : 'file'
})

async function download() {
  const value = props.value
  const location = value && typeof value === 'object' && 'location' in value ? value.location : null
  const downloadLocation = `${endpoint.replace('graphql', 'download')}/${location}`
  const originalName = fileOriginalName.value
  await downloadFile({ downloadLocation, originalName })
}

async function handleClick() {
  if (isFile.value) {
    loading.value = true
    await download()
    loading.value = false
  } else {
    showModal.value = true
  }
}
</script>

<template>
  <div v-if="value == null || value === ''" style="width: 256px" />

  <template v-else>
    <Card :title="valueString" padding="0" hoverable style="width: 256px" @click="handleClick">
      <Row v-if="loading" padding="m" justify="center">
        <Spinner style="height: 24px" />
      </Row>
      <Row v-else padding="m" justify="between">
        <small class="dodo-nowrap no-interaction">
          {{ valueString }}
        </small>
        <TwinIcon v-if="isFile" size="s" icon="Download" color="primary" />
      </Row>
    </Card>

    <LargeModalLayout :open="showModal" :title="modalTitle" size-xl @close="showModal = false">
      <template #content>
        <code>{{ valueString }}</code>
      </template>
    </LargeModalLayout>
  </template>
</template>
