import { computed, ref } from 'vue'
import { useUserOrganization } from './useUserOrganization'

const showTopUp = ref(false)

export function useTopUpModal() {
  const { organization } = useUserOrganization()

  const outOfFunds = computed(() => {
    const budget = organization.value?.budget
    return budget != null && budget < 0
  })

  return { showTopUp, outOfFunds }
}
